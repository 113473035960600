import {Fragment, useEffect, useState} from "react";
import { Menu, Transition } from "@headlessui/react";
import { useRouter } from "next/navigation";
import cn from "classnames";
import ArrowDownIcon from "@/assets/icons/arrow-down.svg";
import useLocale, {setLocale} from "@/hooks/useLocale";

const langList = [
  { id: 1, prefix: "", title: "ENG (UK)" },
  { id: 2, prefix: "usa", title: "ENG (US)" },
  { id: 3, prefix: "de", title: "DE" },
];

export default function LanguageDropdown({
  className,
  iconStyle = "m-2 fill-white hover:fill-black",
  icon = true,
}) {
  const locale = useLocale()
  const [lang, setLang] = useState(langList[0]);
  const router = useRouter();

  const handleLanguageChange = (lang) => {
    setLocale(lang.prefix)
    setLang(lang);

    router.push(`${lang.prefix ? `/${lang.prefix}` : ""}/${router?.asPath ?? ""}`);
  };

  useEffect(() => {
    setLang(langList.find((i) => i.prefix === locale ? true : locale === 'en' && i.prefix === ''))
  }, [locale]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className={className}>
          {lang.title}
          {icon && <ArrowDownIcon width="20" className={`arrow-icon ${iconStyle} group-hover:fill-black`} />}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 origin-top-right w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {langList.map(
              (elem) =>
                elem.id !== lang.id && (
                  <Menu.Item key={elem.id}>
                    {({ active }) => (
                      <button
                        onClick={() => handleLanguageChange(elem)}
                        className={cn(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm w-full text-left",
                        )}
                      >
                        {elem.title}
                      </button>
                    )}
                  </Menu.Item>
                ),
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
